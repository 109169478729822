import React from "react"
import { Link } from "gatsby"
import __c from "../utilities/combineClasses"
import logo from "../images/logo.svg"

import style from "./logo.module.scss"

export default class Logo extends React.Component {
  render = () => {
    const { className } = this.props
    return (
      <Link className={__c([style.link, className])} to="/">
        <img
          className={__c(style.headerContent_LogoImage)}
          src={logo}
          alt="VariaMetrix Logo"
        />
      </Link>
    )
  }
}
