import React from "react"
import { Link } from "gatsby"
import __c from "../utilities/combineClasses"

import style from "./footer.module.scss"
import menuStyle from "./menus.module.scss"

const navClasses = [menuStyle.nav, style.nav]
const listClasses = [menuStyle.list, style.list]
const listItemClasses = [menuStyle.listItem, style.listItem]
const linkClasses = [menuStyle.link, style.link]

export default () => {
  return (
    <>
      <nav className={__c(navClasses)}>
        <ul className={__c(listClasses)}>
          <li className={__c([...listItemClasses, style.listItem_Consulting])}>
            <Link
              className={__c([...linkClasses, style.link_Consulting])}
              to="/consulting"
            >
              Managed consulting services
            </Link>
          </li>
          <li className={__c([...listItemClasses, style.listItem_Agencies])}>
            <Link
              className={__c([...linkClasses, style.link_Agencies])}
              to="/agencies"
            >
              Benefits for agencies
            </Link>
          </li>
          <li className={__c([...listItemClasses, style.listItem_Privacy])}>
            <Link
              className={__c([...linkClasses, style.link_Privacy])}
              to="/privacy-policy"
            >
              Privacy policy
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}
