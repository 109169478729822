import React from "react"
import { Link } from "gatsby"
import __c from "../utilities/combineClasses"

import style from "./header.module.scss"
import menuStyle from "./menus.module.scss"

const listClasses = [menuStyle.list, style.list]
const listItemClasses = [menuStyle.listItem, style.listItem]
const linkClasses = [menuStyle.link, style.link]

export default ({ className }) => {
  return (
    <>
      <nav className={__c([menuStyle.nav, style.nav, className])} data-theme="dark">
        <ul className={__c(listClasses)}>
          {/* <li className={__c([...listItemClasses, style.listItem_Demo])}>
            <Link
              className={__c([...linkClasses, style.link_Demo])}
              to="/demo"
            >
              <span className={__c(style.onlyMobile)}>Demo</span>
              <span className={__c(style.onlyDesktop)}>See it in action</span>
            </Link>
          </li> */}
          <li className={__c([...listItemClasses, style.listItem_Agencies])}>
            <Link
              className={__c([...linkClasses, style.link_Agencies])}
              to="/contact"
            >
              Contact Sales
            </Link>
          </li>
          <li className={__c([...listItemClasses, style.listItem_Agencies])}>
            <Link
              className={__c([...linkClasses, style.link_Agencies])}
              to="/agencies"
            >
              For Agencies
            </Link>
          </li>
          <li className={__c([...listItemClasses, style.listItem_SignIn])}>
            <a
              className={__c([...linkClasses, style.link_SignIn])}
              href="https://dashboard.variametrix.com"
            >
              Sign In
            </a>
          </li>
          {/* <li className={__c([...listItemClasses, style.listItem_SignUp])}>
            <Link
              className={__c([...linkClasses, style.link_SignUp])}
              to="/sign-up"
            >
              Sign Up
            </Link>
          </li> */}
        </ul>
      </nav>
    </>
  )
}
