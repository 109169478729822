import React from "react"
import Logo from "./logo"
import MenuHeader from "../menus/header"
import MenuFooter from "../menus/footer"
import __c from "../utilities/combineClasses"
import logoFooter from "../images/logo-footer.svg"

import style from "./layout.module.scss"

const maxLogoOffset = 64
const logoHeight = 48

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.headerElement = React.createRef()
    this.bannerElement = React.createRef()
    this.resizeTimeout = undefined
  }

  getHeaderElementNaturalSize = () => {
    if (!this.props.banner) return 48
    return this.getBannerElementNaturalSize() + logoHeight + maxLogoOffset + 24
  }

  getBannerElementNaturalSize = () => {
    if (!this.props.banner) return 0
    return this.bannerElement.current.clientHeight
  }

  setHeaderElementOffset = () => {
    let newSize = this.getHeaderElementNaturalSize()
    document.documentElement.style.setProperty(
      "--header-height",
      `${newSize}px`
    )
  }

  calculateBannerSize = (naturalHeight, scrollLength, wait) => {
    if (scrollLength <= wait) return 0
    if (scrollLength >= wait + naturalHeight) return 0 - naturalHeight
    return 0 - scrollLength + wait
  }

  setBannerElementSize = () => {
    let newSize = this.calculateBannerSize(
      this.getBannerElementNaturalSize(),
      window.scrollY,
      maxLogoOffset
    )
    document.documentElement.style.setProperty(
      "--header-banner-height",
      `${newSize}px`
    )
  }

  calculateLogoMarginOffset = scrollLength => {
    if (scrollLength >= maxLogoOffset) return 0
    return (maxLogoOffset - scrollLength) / 2
  }

  setLogoMarginOffset = () => {
    let newSize = this.calculateLogoMarginOffset(window.scrollY)
    document.documentElement.style.setProperty(
      "--header-logo-offset",
      `${newSize}px`
    )
  }

  handlePageScroll = () => {
    this.setBannerElementSize()
    this.setLogoMarginOffset()
  }

  handlePageResize = () => {
    this.setBannerElementSize()
    this.setLogoMarginOffset()
    this.setHeaderElementOffset()
  }

  componentDidUpdate = () => {
    this.setBannerElementSize()
    this.setHeaderElementOffset()
  }

  componentDidMount = () => {
    this.handlePageResize()
    window.addEventListener("resize", this.handlePageResize)
    window.addEventListener("scroll", this.handlePageScroll)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handlePageResize)
    window.removeEventListener("scroll", this.handlePageScroll)
  }

  render = () => {
    const { children, banner, isHome, className } = this.props
    return (
      <>
        <header
          className={__c([style.header, isHome ? style.isHome : false])}
          data-theme="dark"
          ref={this.headerElement}
          data-ishome={isHome}
        >
          <div className={__c(style.headContainer)}>
            <Logo className={__c(style.logo)} />
            <MenuHeader className={__c(style.menuHeader)} />
          </div>
          {banner ? (
            <div className={__c(style.banner)}>
              <div ref={this.bannerElement}>{banner}</div>
            </div>
          ) : (
            false
          )}
          {isHome ? <div className={__c(style.homeBackground)} /> : false}
        </header>
        <div className={__c(style.scrollableContent)}>
          <main
            className={__c([style.mainContent, className])}
            data-theme="default"
          >
            {children}
          </main>
          <footer className={__c(style.footer)} data-theme="dark">
            <img
              className={__c(style.footer_Logo)}
              src={logoFooter}
              alt="VariaMetrix Logo"
            />
            <MenuFooter />
          </footer>
        </div>
      </>
    )
  }
}
